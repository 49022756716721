import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionsRoutingModule } from './collections-routing.module';
import { SlbButtonModule } from '@slb-dls/angular-material/button';
import { SlbPaginationControlModule } from '@slb-dls/angular-material/pagination-control';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService, SlbNotificationModule } from '@slb-dls/angular-material/notification';
import { SharedModule } from '../../shared/shared.module';
import { MaterialModule } from 'src/app/core/module/material.module';
import { SlbFormFieldModule } from '@slb-dls/angular-material/form-field';
import { UpdateCollectionComponent } from './create-update-collection/update-collection.component';
import {ViewCollectionComponent} from "./view-collection/view-collection.component";
import {SlbDlsGridModule} from "@slb-dls/angular-material/ag-grid";
import { FlexLayoutModule } from '@angular/flex-layout';
import {ViewModelFoldersComponent} from "./view-collection/view-model-folders.component";
import {UploadModelDialog} from "./view-collection/dialogs/upload-model.dialog";
import {CoreModule} from "../../../core/core.module";
import {ModelTreeComponent} from "./view-collection/model-tree.component";
import {ModelFoldersComponent} from "./view-collection/model-folders.component";
import {EditFileDialog} from "./view-collection/dialogs/edit-file.dialog";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {DeleteFileConfirmationDialog} from "./view-collection/dialogs/delete-file-confirmation.dialog";
import {CompareFileDialog} from "./view-collection/dialogs/compare-file.dialog";
import {CheckNameDirective} from "./create-update-collection/check-name.directive";

@NgModule({
  declarations: [
    CheckNameDirective,
    UpdateCollectionComponent,
    ViewCollectionComponent,
    ViewModelFoldersComponent,
    UploadModelDialog,
    ModelTreeComponent,
    ModelFoldersComponent,
    EditFileDialog,
    DeleteFileConfirmationDialog,
    CompareFileDialog
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CollectionsRoutingModule,
        SlbButtonModule,
        SlbNotificationModule,
        SlbFormFieldModule,
        SlbPaginationControlModule,
        MaterialModule,
        SharedModule,
        SlbDlsGridModule,
        FlexLayoutModule,
        CoreModule,
        MatSlideToggleModule
    ],
  exports:[ CheckNameDirective],
  providers:[MessageService],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class CollectionsModule { }

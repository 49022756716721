import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ModelUploadTaskInProgress } from '../models/model-upload-task-in-progress';


export interface ModelDownloadTaskInProgress {
  Id: string;
  DisplayTitle: string;
  Progress: number;
  Status: string;
  RootId: string;
  ParentId: string;
  Data?: any;
  Clear?: boolean
}


@Injectable({
  providedIn: 'root'
})

export class ModelDownloadTaskInProgressService {

  static TaskInProgressStatus = "in-progress";
  static TaskSuccessStatus = "success";
  static TaskFailureStatus = "failure";

  public tasksInProgress: ModelDownloadTaskInProgress[] = [];
  private parentTasks: ModelDownloadTaskInProgress[] = [];

  private _taskInProgressSub: Subject<ModelDownloadTaskInProgress> = new Subject();
  get taskInProgress$(): Observable<ModelDownloadTaskInProgress> {
    return this._taskInProgressSub.asObservable();
  }

  private _parentTasksSub = new BehaviorSubject<ModelDownloadTaskInProgress[]>([]);
  get parentTasksInProgress$(): Observable<ModelDownloadTaskInProgress[]> {
    return this._parentTasksSub.asObservable();
  }

  private _completedTaskSub = new BehaviorSubject<ModelDownloadTaskInProgress>(null);
  get completedTask$(): Observable<ModelDownloadTaskInProgress> {
    return this._completedTaskSub.asObservable();
  }

  constructor() {

    //put in localstorage if the root task in not complete so that when refreshed  we get the status of each root task
    this._taskInProgressSub.pipe(
      tap(p => {
        if (!p.ParentId) {
          if (p.Progress >= 100)
            this._completedTaskSub.next(p);

          //parent tasks
          this.updateTaskProgress(p, this.parentTasks);
          this.parentTasks = this.parentTasks.filter(p => p.Progress < 100);
          this._parentTasksSub.next(this.parentTasks);
        }
      })).subscribe();

    //parent tasks
    this.parentTasks = this.tasksInProgress.filter(p => !p.ParentId && p.Progress < 100);
    this._parentTasksSub.next(this.parentTasks);
  }

  updateProgress(updatedTask: ModelDownloadTaskInProgress): void {
    if(updatedTask.Clear) {
      let currentTask = this.tasksInProgress.find(x => x.Id === updatedTask.Id);
      if(currentTask) {
        this.tasksInProgress = this.tasksInProgress.filter(p => p.Id !== updatedTask.Id && p.ParentId !== updatedTask.Id);
      }
    }

    const updated = this.updateTaskProgress(updatedTask, this.tasksInProgress);
    if (updated) {
      let currentTask = this.tasksInProgress.find(x => x.Id === updatedTask.Id);
      this._taskInProgressSub.next(currentTask);
      if (!!currentTask.ParentId) {
        const childTasksProgress = this.tasksInProgress.filter(p => p.ParentId === currentTask.ParentId).map(p => p.Progress);
        const parentTask = this.tasksInProgress.find(p => p.Id === currentTask.ParentId);
        if (parentTask) {
          let avg = 0;
          childTasksProgress.forEach(p => { avg = avg + p; });
          avg = avg / childTasksProgress.length;
          // comments console.log('min, max, avg', min, max, avg)
          const progress = avg;
          // comments console.log('progress', progress, parentTask.Progress)
          if (progress > parentTask.Progress) {
            parentTask.Progress = progress;
            this._taskInProgressSub.next(parentTask);
          }

        }
      }
    }
  }

  updateTaskProgress(updatedTask: ModelDownloadTaskInProgress, tasksInProgress: ModelDownloadTaskInProgress[]): boolean {
    let currentTask = tasksInProgress.find(x => x.Id === updatedTask.Id);
    let updated = false;
    if (!currentTask) {
      tasksInProgress.push(updatedTask);
      updated = true;
    } else if (currentTask.Progress <= updatedTask.Progress) {
      currentTask.Progress = updatedTask.Progress;
      currentTask.Status = updatedTask.Status;
      currentTask.Data = updatedTask.Data ?? currentTask.Data;
      updated = true;
    }
    return updated;
  }



}


import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CollectionsService } from 'src/app/core/api/collections.service';
import { CreateCollectionDto } from 'src/app/core/models/create-collection-dto';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { catchError, Observable, of, startWith, tap, zip, mergeMap, from, toArray } from "rxjs";
import { EntitlementGroup } from "../../../../core/models/entitlement-group";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { map } from "rxjs/operators";
import { Simulation } from 'src/app/core/models/export class simulation';
import { CollectionsNeededService } from "../../collections/collectionsneeded.service";
import { CompanionDataService } from 'src/app/core/api/companion-data.service';
import { autoMonitorPageView } from 'src/app/core/decorators/auto-monitor-page-view.decorator';
import { MonitoringService } from 'src/app/core/services/monitoring.service';
import { BrowserStorageService } from 'src/app/core/services/storage.service';

@autoMonitorPageView({name: 'Update Collection', trackOnInitToAfterInit: false })
@Component({
  selector: 'app-update-collection',
  templateUrl: './update-collection.component.html',
  styleUrls: ['./update-collection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateCollectionComponent implements OnInit, AfterViewInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  title: string = 'New Collection';
  isDisabled: boolean = false;
  collectionform: FormGroup = new FormGroup({});
  submitted: boolean = false;
  ownerEntitlementGroups: (EntitlementGroup & { isConfiguredInBu: boolean, text: string })[] = [];
  filteredOwnerEntitlementGroups: Observable<(EntitlementGroup & { isConfiguredInBu: boolean, text: string })[]>;
  viewerEntitlementGroups: (EntitlementGroup & { isConfiguredInBu: boolean, text: string })[] = [];
  filteredViewerEntitlementGroups: Observable<(EntitlementGroup & { isConfiguredInBu: boolean, text: string })[]>;
  ownerCtrl = new FormControl('');
  viewerCtrl = new FormControl('', { validators: [], updateOn: 'change' });
  tagCtrl = new FormControl('', { validators: [cannotContainSpaceCommaValidator()], updateOn: 'change' });

  @ViewChild('ownerInput') ownerInput: ElementRef<HTMLInputElement>;
  @ViewChild('viewerInput') viewerInput: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<UpdateCollectionComponent>,
    private collectionService: CollectionsService,
    private cdr: ChangeDetectorRef,
    //private toastrService: ToastrService,
    //private loadingService: SlbLoadingService,
    //private entitlementsService: EntitlementsService,
    private oneService: CollectionsNeededService,
    private companionDataService: CompanionDataService,
    @Inject(MAT_DIALOG_DATA) private data: string,
    private storageService: BrowserStorageService
  ) {
    this.oneService.loadingService.showSpinner({ text: 'Loading...' });

    this.buildCollectionForm();

    this.filteredOwnerEntitlementGroups = this.ownerCtrl.valueChanges.pipe(
      startWith(null),
      map((owner: string | null) => (owner ? this._filter(owner, this.ownerEntitlementGroups, this.collectionform.controls['owners'].value) : this.ownerEntitlementGroups.slice())),
    );
    this.filteredViewerEntitlementGroups = this.viewerCtrl.valueChanges.pipe(
      startWith(null),
      map((viewer: string | null) => (viewer ? this._filter(viewer, this.viewerEntitlementGroups, this.collectionform.controls['viewers'].value) : this.viewerEntitlementGroups.slice())),
    );

    if (this.data) {
      this.title = 'Modify Collection';
    }

    zip(this.oneService.entitlementsService.getMyGroupsWithBuInfo(), !!data ? this.collectionService.getCollection(data) : of(null))
      .pipe(tap(([myGroups, collDetails]) => {
        this.ownerEntitlementGroups = myGroups.filter(p => p.email.indexOf('.owners') > -1);
        this.viewerEntitlementGroups = myGroups.filter(p => p.email.indexOf('.viewers') > -1);
        const viewersToBeAdded = this.ownerEntitlementGroups.filter(p => {
          const respectiveViewer = p.name.replace('.owner', '.viewer');
          return !this.viewerEntitlementGroups.some(x => x.name.toLocaleLowerCase() === respectiveViewer.toLocaleLowerCase());
        }).map(p => {
          return {
            email: p.email.replace('.owners', '.viewers'),
            name: p.name.replace('.owners', '.viewers'),
            text: p.text?.replace('.owners', '.viewers'),
            isConfiguredInBu: false,
            isDefault: false
          };
        });
        this.viewerEntitlementGroups = this.viewerEntitlementGroups.concat(viewersToBeAdded);
        if (!collDetails) {
          this.collectionform.controls['owners'].setValue(this.ownerEntitlementGroups.filter(p => p.isDefault).map(p => p.email));
          this.collectionform.controls['viewers'].setValue(this.viewerEntitlementGroups.filter(p => p.isDefault).map(p => p.email));
          const buTag = this.getDefaultBuTag(this.viewerEntitlementGroups.filter(p => p.isDefault).map(p => p.name));
          this.collectionform.controls['tags'].setValue(buTag);
        } else {
          this.collectionform.controls['name'].setValue(collDetails.name);
          this.collectionform.controls['description'].setValue(collDetails.description);
          this.collectionform.controls['tags'].setValue(collDetails.tags);
          this.collectionform.controls['owners'].setValue(collDetails.owners);
          this.collectionform.controls['viewers'].setValue(collDetails.viewers);
        }
        this.oneService.loadingService.closeSpinner();

      })).subscribe();

  }
  ngAfterViewInit(): void {
    // comments do nothing
  }

  ngOnInit(): void {
    console.log('on init NewCollectionComponent');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  buildCollectionForm() {
    this.collectionform = this.fb.group({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      tags: new FormControl([]),
      owners: new FormControl([]),
      viewers: new FormControl([]),
    });
  }

  get f() { return this.collectionform != null ? this.collectionform.controls : null }

  private getDefaultBuTag(viewerNames: string[]) {
    if(viewerNames.some(p => p.toLocaleLowerCase().includes("gom"))) {
      return ["BU:GOM"];
    }
    else if(viewerNames.some(p => p.toLocaleLowerCase().includes("abu"))) {
      return ["BU:ABU"];
    }
    else {
      let bu = this.storageService.get('selectedBu') ?? 'General';
      bu = bu.replace(/['"]+/g, '');
      return [`BU:${bu}`];
    }
  }

  onCancel() {
    this.collectionform.reset();
    this.dialog.close();
  }

  onSave(collection: CreateCollectionDto) {
    // save the selected entitlement groups
    this.oneService.loadingService.showSpinner({ text: 'Loading...' });
    if (!this.data) {
      this.collectionService.createCollection(collection).pipe(catchError(err => { console.error('error creating collection', err); return of(null); })).subscribe(result => {
        if (result) {
          this.oneService.toastrService.success('New collection created successfully!!');
          this.oneService.loadingService.closeSpinner();
          this.dialog.close(result.id);
        } else {
          this.oneService.toastrService.error('There was an error while creating the collection!!');
          this.oneService.loadingService.closeSpinner();
          this.dialog.close();
        }

      });
    } else {
      this.collectionService.updateCollection(Object.assign(collection, { id: this.data })).pipe(
        catchError(err => { console.error('error updating collection', err); return of(null); }),
        mergeMap(response => {
          if (response) {
            // dispatch to update companion data
            return this.updateCompanionData(collection).pipe(
              catchError(err => { console.error('error updating companiondata after', err); return of(null); }),
            );
          }
          else {
            return of(null);
          }
        })
        // tap(response => {
        //   if (response) {
        //     //dispatch to update related
        //     this.updateCollectionRelated(collection)
        //     this.oneService.toastrService.success('collection and associated data were updated successfully!!')
        //     this.oneService.loadingService.closeSpinner()
        //     this.dialog.close(collection)
        //   } else {
        //     this.oneService.toastrService.error('There was an error while updating the collection!!')
        //     this.oneService.loadingService.closeSpinner()
        //     this.dialog.close()
        //   }
        // }
      ).subscribe( response => {
        if(response) {
          console.log("updater has response:", response);
          this.oneService.toastrService.success('collection and associated data were updated successfully!!');
          this.oneService.loadingService.closeSpinner();
          this.dialog.close(collection);
        }
        else {
          this.oneService.toastrService.error('There was an error while updating the collection!!');
          this.oneService.loadingService.closeSpinner();
          this.dialog.close();
        }
      });
    }

  }

  updateCompanionData(collection: CreateCollectionDto) : Observable<any> {
    return of(true).pipe(
      mergeMap(_ => {
        return this.collectionService.getSimulationsForCollection(this.data);
      }),
      mergeMap((models: Simulation[]) => 
        // from emit each model in models array
        from(models).pipe(
          // for each model
          mergeMap(model => {
            let newEntitlements = [];
            newEntitlements = newEntitlements.concat(collection.viewers);
            newEntitlements = newEntitlements.concat(collection.owners);
            const headerInfo = {
               id: '',
               fileName: model.name,
               modelId: model.id,
               branchSimulationId: model.branchSimulationId,
               companionMetaData: {entitlements: newEntitlements, collectionName: collection.name, collectionIdentifier: this.data}
            }
            return this.companionDataService.upsetCompanionData(headerInfo);
          }),
          toArray(),
          map(records => {
            if(records && records.length > 0 && records[0]?.recordIds?.length > 0) {
              return true;
            }
            else {
              return false;
            }
          })
        )
      )
    )
  }

  // update collection related
  updateCollectionRelated(collection: CreateCollectionDto) {
    let newEntitlements = [];
    newEntitlements = newEntitlements.concat(collection.viewers);
    newEntitlements = newEntitlements.concat(collection.owners);
    this.collectionService.getSimulationsForCollection(this.data).pipe(tap((models: Simulation[]) => {
      models.forEach(model => {
        const headerInfo = {
          id: '',
          fileName: model.name,
          modelId: model.id,
          branchSimulationId: model.branchSimulationId,
          companionMetaData: {entitlements: newEntitlements, collectionName: collection.name, collectionIdentifier: this.data}
        }
        this.companionDataService.upsetCompanionData(headerInfo).subscribe();
      })
    })).subscribe()
  }

  addTag(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (!this.tagCtrl.errors) {
      this.addChip(value, 'tags');
      event.chipInput!.clear();
    }

  }

  removeTag(tag: string) {
    this.removeChip(tag, 'tags');
  }

  addOwner(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (this.ownerEntitlementGroups.some(p => p.email === value)) {
      this.addChip(value, 'owners');
      event.chipInput!.clear();

      const respectiveViewer = value.replace('.owners', '.viewers');
      this.addChip(respectiveViewer, 'viewers');
    }
  }

  removeOwner(owner: string) {
    this.removeChip(owner, 'owners');
  }

  addViewer(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (this.viewerEntitlementGroups.some(p => p.email === value)) {
      this.addChip(value, 'viewers');
      const buTag = this.getDefaultBuTag([value]);
      this.addChip(buTag[0], "tags");
      event.chipInput!.clear();
    }
  }

  removeViewer(viewer: string) {
    this.removeChip(viewer, 'viewers');
    const buTag = this.getDefaultBuTag([viewer]);
    this.removeChip(buTag[0], "tags");
  }

  private addChip(value: string, propName: string) {

    if (value) {
      const existing = this.collectionform.controls[propName].value as string[];
      // check duplication
      const hasOne = existing.find(p => p === value) ?? null;
      if(hasOne === null) {
        existing.push(value);
        this.collectionform.controls[propName].setValue(existing);
      }
    }

  }

  private removeChip(chipText: string, propName: string) {
    const existingChips = this.collectionform.controls[propName].value as string[];
    const index = existingChips.indexOf(chipText);

    if (index >= 0) {
      existingChips.splice(index, 1);
      this.collectionform.controls[propName].setValue(existingChips);
    }
  }

  selectedOwner(event: MatAutocompleteSelectedEvent): void {
    this.addChip(event.option.value, 'owners');
    this.ownerInput.nativeElement.value = '';
    this.ownerCtrl.setValue(null);

    const respectiveViewer = event.option.value.replace('.owners', '.viewers');
    this.addChip(respectiveViewer, 'viewers');
  }


  selectedViewer(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.addChip(value, 'viewers');
    const buTag = this.getDefaultBuTag([value]);
    this.addChip(buTag[0], "tags");
    this.viewerInput.nativeElement.value = '';
    this.viewerCtrl.setValue(null);
  }

  private _filter(value: string, groups: (EntitlementGroup & { isConfiguredInBu: boolean, text: string })[], existing: string[]): (EntitlementGroup & { isConfiguredInBu: boolean, text: string })[] {
    const filterValue = value.toLowerCase();
    return groups.filter(p => p.text.toLowerCase().includes(filterValue) && !existing.some(x => p.email.toLowerCase() === x.toLowerCase()));
  }

}

export function cannotContainSpaceCommaValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ((control.value as string).indexOf(' ') > -1) {
      return { cannotContainSpace: true }
    } else if ((control.value as string).indexOf(',') > -1) {
      return { cannotContainComma: true }
    }
    return null;
  };
}

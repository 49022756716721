import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
  selector: '[checkName]'
})
export class CheckNameDirective {
    
    constructor(private eleREf: ElementRef) {
    }
    
    // read the value and check with regex pattern, then SILENTLY remove non-compatible chars
    @HostListener('change')
    OnChanges($event){
      let ele =  this.eleREf.nativeElement as HTMLInputElement;
      let value: string = ele.value.trim() as string;
      const invalid = /[^ a-zA-Z0-9_-]+/.test(value);
      if(invalid) {
        ele.value = value.replace(/[^ a-zA-Z0-9_-]/g, "");
      }
    }
  }